import React, {useEffect, useContext} from 'react'
import {videoHolder } from '../pages.module.scss'
import {SectionNavContext} from '../../components/utilities/SiteContexts'
import { motion, useAnimation , usePresence} from "framer-motion"
import DownArrow from '../../images/svgs/home/DownArrow.svg'
import { Link } from 'gatsby'


const arrowBounce = {
  pre: {
    opacity:0
  },
  vis: {
    opacity:1,
    y: [10,0,10,0,10],
    transition: {
      repeat: Infinity,
      repeatDelay: 3,
      bounce: 0.7,
      type:'spring',
      mass:2,
      delay:1
    }
  },
  post: {
    opacity:0
  },
}


const pageVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type:'tween',
      duration:0.5
    }
  }
}

export default function VideoPage() {

  const [isPresent, safeToRemove] = usePresence()
  const pageControl = useAnimation()
  const arrowControls = useAnimation()


  const [, setSectionNav] = useContext(SectionNavContext)

  useEffect(() => {
    const introAnimation = async ()=> {
      await pageControl.start('vis')
      arrowControls.start('vis')

    }

    introAnimation()

    setSectionNav([])
  }, [])

  useEffect( ()=>{

    const exitAnimation = async () => {
      await pageControl.start('post')
      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation()
    }
  },[pageControl, isPresent, safeToRemove])


  useEffect(() => {
    setSectionNav([
      {
        to: '/',
        title: 'Home'
      },
      {
        to: '/about/video',
        title: 'Video',
        active:true
      },
      { 
        to: '/about/benefits',
        title: 'Benefits'
      },
      {
        to: '/about/expertise',
        title: 'Expertise',
      },
      {
        to: '/about/achievements',
        title: 'Achievements'
      },
      {
        to: '/about/contact',
        title: 'Contact'
      }
    ])
  }, [setSectionNav])


  return (
    <motion.article className={videoHolder}  variants={pageVariants} animate={pageControl} initial="pre">

        <video src="https://carbonclimates.wpengine.com/wp-content/uploads/2021/02/Carbon_Climate_Video-1.mp4" autoPlay="true" controls="true" controlslist="nodownload"></video>

        <motion.div className="downArrow" variants={arrowBounce} initial="pre" animate={arrowControls}><Link to='/about/benefits'><DownArrow /></Link></motion.div>

    </motion.article>
  )
}
